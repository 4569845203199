import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";

function Directories() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <div>
      <div className="md:px-4 md:py-8">
        <div className="mx-auto w-auto h-auto md:py-4">

          <div className="flex justify-center flex-col items-center pb-5">
            <h2 className="text-center pb-5">Our Certifications</h2>
            <img
              src={"https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/Insignias%2FBBB%20Certificate.png?alt=media&token=d69f3620-e799-480b-917e-8ebe6ef8c165"}
              alt="Not found"
              width={'300px'}
              className="rounded-lg bg-white"
            />
          </div>

        </div>

        <div className="flex mx-auto md:flex-row flex-col justify-center gap-5">



          <div className="flex justify-center items-center pb-5">
            {
              rpdata?.gmb?.link.length > 1 ?
                <a href={rpdata?.gmb?.link} target="_blank" rel='noopener noreferrer'>
                  <img src={"https://firebasestorage.googleapis.com/v0/b/imagenes-de-stock-y-redes.appspot.com/o/banderas%2FSello-Google.png?alt=media&token=18da2ce4-4aa7-4dde-a826-3665b076b811"} alt="Not found" width={'250px'} />
                </a>
                : null
            }
          </div>
          <div className="flex justify-center items-center pb-5">
            {
              rpdata?.gmb?.link.length > 1 ?
                <a href={rpdata?.gmb?.link} target="_blank" rel='noopener noreferrer'>
                  <img src={rpdata?.gmb?.img} alt="Not found" width={'250px'} />
                </a>
                : null
            }
          </div>
        </div>
        {
          rpdata?.dbSocialMedia?.redes.length > 0 || rpdata?.dbSocialMedia?.directorios.length > 0 ?
            <h2 className="text-center pb-5">
              Find Us On
            </h2>
            : null
        }
        {/* <div className={`grid ${rpdata?.dbSocialMedia?.directorios?.length > 3 || rpdata?.dbSocialMedia?.redes?.length > 3 ? 'md:grid-cols-4' : 'md:grid-flow-col' } grid-cols-1 gap-4 bg-auto md:bg-contain bg-top-[100px]`}> */}
        <div className={`w-4/5 mx-auto flex flex-wrap justify-center items-center gap-4 pb-5`}>
          {
            rpdata?.dbSocialMedia?.directorios.length > 0 ?
              rpdata?.dbSocialMedia?.directorios.map((item, index) => {
                return (
                  <div
                    className="text-center content-directorios"
                    data-aos="zoom-in"
                    data-aos-duration="1800"
                    key={index}
                  >
                    <a href={item.url} target="_blank" rel='noopener noreferrer'>
                      <img src={item.image} alt="Not found" width={'250px'} />
                    </a>
                  </div>
                );
              }) : null
          }
          {
            rpdata?.dbSocialMedia?.redes.length > 0 ?
              rpdata?.dbSocialMedia?.redes.map((item, index) => {
                return (
                  <div
                    className="text-center content-directorios"
                    data-aos="zoom-in"
                    data-aos-duration="2200"
                    key={index}
                  >
                    <a href={item.url} target="_blank" rel='noopener noreferrer'>
                      <img src={item.image} alt="Not Found" width={'250px'} />
                    </a>
                  </div>
                );
              }) : null
          }
        </div>
      </div>
    </div>
  );
}

export default Directories;
